import React from 'react'
import { SessionContext } from './layout'
import FormField from './FormField'

class Authorize extends React.Component {
  static contextType = SessionContext

  constructor(props) {
    super(props)

    this.state = {
      email: '',
      role: null,
      error: null,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  async handleSubmit(event) {
    event.preventDefault()

    this.setState({ error: null }, () => {
      this.context.Firebase.callFunction('addRole', {
        email: this.state.email,
        role: this.state.role,
      })
    })
  }

  render() {
    return (
      <div className="grid-x grid-margin-x align-center">
        <div className="cell large-4 medium-6">
          <h3>Grant User Role</h3>
          {this.state.error ? (
            <div className="alert callout">
              <p>{this.state.error.message}</p>
            </div>
          ) : null}
          <form onSubmit={this.handleSubmit}>
            <FormField
              label="Email"
              name="email"
              type="text"
              value={this.state.email}
              onChange={this.handleInputChange}
              placeholder="jane@example.com"
              error={this.state.error}
            />
            <label>
              Role
              <select name="role" onChange={this.handleInputChange}>
                <option>Select</option>
                <option value="admin">Admin</option>
                <option value="organizer">Organizer</option>
              </select>
            </label>
            <button className="button" type="submit">
              Save
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default Authorize
