import React from 'react'
import { SessionContext } from './layout'
import { CSVLink, CSVDownload } from 'react-csv'

class ExportData extends React.Component {
  static contextType = SessionContext

  constructor(props) {
    super(props)

    this.state = {
      location: null,
    }

    this.state = {}
  }

  async componentDidMount() {
    // const event = await this.context.Firebase.fetch('events', this.props.eventID)
    const location = await this.context.Firebase.fetch(
      `events/${this.props.eventID}/locations`,
      this.props.locationID
    )

    let people = {}
    this.context.Firebase.fetch('people', {
      where: [['location', '==', location.ref]],
    }).then(data => {
      data.forEach(d => {
        const { name, email } = d.data()
        people[d.id] = { name, email }
      })
    })

    let booths = {}
    this.context.Firebase.fetch(`events/${this.props.eventID}/booths`, {
      where: [['locations', 'array-contains', location.ref], ['active', '==', true]],
    }).then(data => {
      data.forEach(d => {
        const { name } = d.data()
        booths[d.id] = { name }
      })
    })

    const snapshot = await this.context.Firebase.firestore
      .collectionGroup('entries')
      .where('location', '==', location.ref)
      .get()

    let entries = []
    snapshot.docs.forEach(doc => {
      const id = doc.id
      const person_id = doc.ref.parent.parent.id
      const { name, email } = people[person_id]
      const created_at = doc.data().created_at.toDate()
      const booth = booths[doc.data().booth.id].name
      let e = { id, booth, name, email, person_id, created_at }

      entries.push(Object.values(e))
    })

    this.setState({ location, entries })
  }

  render() {
    return this.state.entries ? (
      <CSVLink filename={`${this.state.location.name}.csv`} data={this.state.entries}>
        Download
      </CSVLink>
    ) : null
  }
}
export default ExportData
