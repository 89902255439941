import React, { Fragment } from 'react'
import Layout, { SessionContext } from './layout'
import { Link } from 'gatsby'
import moment from 'moment'

import { CSVLink, CSVDownload } from 'react-csv'

class Drawing extends React.Component {
  static contextType = SessionContext

  constructor(props) {
    super(props)

    this.state = {
      location: null,
    }
    this.state = {}
  }

  async componentDidMount() {
    // const event = await this.context.Firebase.fetch('events', this.props.eventID)
    const drawing = await this.context.Firebase.fetch(`drawings`, this.props.drawingID)
    const event = await this.context.Firebase.fetch('events', drawing.event.id)

    const people = await this.context.Firebase.fetch('people', {
      where: [['drawingIDs', 'array-contains', drawing.docID]],
    })

    const drawingMarkerPaths = drawing.markers.map(m => {
      return m.path
    })
    const allMarkers = await this.context.Firebase.fetch(`${event.path}/markers`)
    // filter out markers that are not part of this drawing
    let markers = allMarkers.filter(m => {
      return drawingMarkerPaths.includes(m.path)
    })

    const categories = await this.context.Firebase.fetch(`${event.path}/categories`)

    const entries = await this.context.Firebase.fetch(`${drawing.path}/entries`)

    // calculate ratings if necessary
    if (event.collect_ratings) {
      markers.map(m => {
        m.numRatings = 0
        m.totalRating = 0

        entries.forEach(e => {
          if (e.marker.id == m.docID) {
            m.numRatings++
            m.totalRating += e.rating
          }
        })

        m.avgRating = Math.round((m.totalRating / m.numRatings) * 100) / 100
      })
    }

    const _start = moment(drawing.start_time.toDate())
    const _end = moment(drawing.end_time.toDate())
    const _now = moment()
    const _status = _now.isBetween(_start, _end)
      ? 'success'
      : _now.isBefore(_start)
      ? 'primary'
      : 'secondary'

    this.setState({
      drawing,
      people,
      event,
      entries,
      markers,
      _start,
      _end,
      _status,
      categories,
      breadcrumbs: [
        <Link to="/app/events">Events</Link>,
        <Link to={`/app/${event.path}`}>{event.name}</Link>,
        <>{drawing.docID}</>,
      ],
    })
  }

  async calculateRatings() {
    let markers = this.state.markers
  }

  async gatherDataForExport() {
    let peopleData = {}
    this.state.people.forEach(p => {
      const { name, email } = p
      peopleData[p.docID] = { name, email }
    })

    let markerData = {}
    this.state.markers.forEach(m => {
      const { name } = m
      markerData[m.docID] = { name }
    })

    let entriesForExport = []
    this.state.entries.forEach(e => {
      const { name: personName, email } = peopleData[e.person_id]
      const { name: marker } = markerData[e.marker.id]
      let x = {
        docID: e.docID,
        marker,
        personName,
        email,
        personID: e.person_id,
        createdAt: e.created_at.toDate(),
      }
      entriesForExport.push(Object.values(x))
    })

    this.setState({ entriesForExport })
  }

  render() {
    return this.state.drawing ? (
      <>
        <a className="button tiny float-right" onClick={() => this.gatherDataForExport()}>
          <i className="icon-download"></i> Download Submissions (CSV)
        </a>
        <h3>{this.state.drawing.name}</h3>

        {this.state.entriesForExport ? (
          <CSVDownload
            filename={`${this.state.drawing.name}.csv`}
            data={this.state.entriesForExport}
            target="_blank"
          />
        ) : null}
        <div className={`callout ${this.state._status}`}>
          <p>
            <i className="icon-code"></i>&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>{this.state.drawing.docID}</strong>
          </p>
          <p>
            <i className="icon-users"></i>&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>{this.state.people.length}</strong> app users
          </p>
          <p>
            <i className="icon-calendar"></i>&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state._start.format('MMM D, YYYY, h:mm a')} -{' '}
            {this.state._end.format('MMM D, YYYY, h:mm a')}
          </p>
        </div>

        <h5>Image Markers</h5>

        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>ID</th>
              {this.state.event.collect_ratings ? <th>Avg. Rating</th> : null}
            </tr>
          </thead>
          <tbody>
            {this.state.categories.map(c => {
              return (
                <Fragment key={c.docID}>
                  <tr className="category-row">
                    <th colSpan="3" className="text-left">
                      {c.name}
                    </th>
                  </tr>
                  {this.state.markers
                    .filter(m => {
                      return m.category.path == c.path
                    })
                    .map(m => {
                      return (
                        <tr key={m.docID}>
                          <td>
                            <strong>{m.name}</strong>
                          </td>
                          <td>
                            <small>{m.docID}</small>
                          </td>

                          {this.state.event.collect_ratings ? (
                            <td className="text-center">{m.avgRating}</td>
                          ) : null}
                        </tr>
                      )
                    })}
                </Fragment>
              )
            })}
          </tbody>
        </table>

        <h5>People</h5>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            {this.state.people.map((p, i) => {
              return (
                <tr key={p.docID}>
                  <td>{i + 1}</td>
                  <td>
                    <strong>{p.name}</strong>
                  </td>
                  <td>{p.email ? <a href={`mailto:${p.email}`}>{p.email}</a> : null}</td>
                  <td>
                    <small>{p.docID}</small>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </>
    ) : null
  }
}
export default Drawing
