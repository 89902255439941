import React from 'react'
// import { navigate, Link } from 'gatsby'
import { Router } from '@reach/router'
import Events from '../components/Events'
import Layout from '../components/layout'
import Event from '../components/Event'
import Drawing from '../components/Drawing'
import Authorize from '../components/Authorize'
import ExportData from '../components/ExportData'

const App = () => {
  return (
    <Layout>
      <Router>
        <Events path="/app/events" />
        <Event path="/app/events/:eventID" />
        <Drawing path="/app/drawings/:drawingID" />
        <ExportData path="/app/drawings/:drawingID/export" />
        <Authorize path="/app/authorize" />
      </Router>
    </Layout>
  )
}

export default App
