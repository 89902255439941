import React from 'react'
import Layout, { SessionContext } from './layout'
import { Link } from 'gatsby'
import moment from 'moment'

class Event extends React.Component {
  static contextType = SessionContext

  constructor(props) {
    super(props)

    this.state = {
      event: null,
    }
    this.state = {}
  }

  async componentDidMount() {
    const event = await this.context.Firebase.fetch('events', this.props.eventID)
    const eventRef = this.context.Firebase.firestore.doc(event.path)
    const drawings = await this.context.Firebase.fetch(`drawings`, {
      where: [['event', '==', eventRef]],
    })
    const markers = await this.context.Firebase.fetch(`${event.path}/markers`)
    this.setState(() => {
      return {
        event,
        drawings,
        markers,
        breadcrumbs: [<Link to="/app/events">Events</Link>, <>{event.name}</>],
      }
    })
  }

  render() {
    return this.state.event ? (
      <>
        <h3>{this.state.event.name}</h3>
        <h5>Drawings</h5>
        <p>All times are Central Standard Time.</p>
        <div className="table-scroll">
          <table className="hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Key</th>
                <th>Starts</th>
                <th>Ends</th>
              </tr>
            </thead>
            <tbody>
              {this.state.drawings.map(d => {
                const _start = moment(d.start_time.toDate())
                const _end = moment(d.end_time.toDate())
                return (
                  <tr key={d.docID}>
                    <td>
                      <Link to={`/app/${d.path}`}>
                        <strong>{d.name}</strong>
                      </Link>
                    </td>
                    <td>
                      <small>{d.docID}</small>
                    </td>
                    <td className="text-light-gray">
                      <small>{_start.format('MMM D, YYYY, h:mm a')}</small>
                    </td>
                    <td className="text-light-gray">
                      <small>{_end.format('MMM D, YYYY, h:mm a')}</small>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <h5>Image Markers</h5>
        <div className="table-scroll">
          <table className="hover">
            <thead>
              <tr>
                <th className="text-center">Active?</th>
                <th>Name</th>
                <th>Category</th>
                <th>ID</th>
                <th className="text-center">Marker</th>
              </tr>
            </thead>
            <tbody>
              {this.state.markers.map(m => {
                return (
                  <tr key={m.docID} className={m.active ? null : 'text-light-gray'}>
                    <td className="text-center">
                      <i className={`icon-${m.active ? 'check text-green' : 'x text-red'}`}></i>
                    </td>
                    <td>{m.name}</td>
                    <td>
                      <small>{m.category.path.split('/').pop()}</small>
                    </td>
                    <td>
                      <small>{m.docID}</small>
                    </td>
                    <td className="text-center">
                      <small>
                        <a href={m.target.source.uri} target="_blank">
                          View Image
                        </a>
                      </small>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </>
    ) : null
  }
}
export default Event
