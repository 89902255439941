import React from 'react'
import Layout, { SessionContext } from './layout'
import { Link } from 'gatsby'

class Events extends React.Component {
  static contextType = SessionContext

  constructor(props) {
    super(props)

    this.state = {
      events: null,
    }
    this.breadcrumbs = [<>Events</>]

    this.state = {}
  }

  async componentDidMount() {
    const events = await this.context.Firebase.fetch('events')
    this.setState({ events })
  }

  render() {
    return this.state.events ? (
      <>
        <h3>Events</h3>
        <div className="grid-x grid-margin-x">
          <table>
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {this.state.events.map(e => {
                return (
                  <tr key={e.docID}>
                    <td>
                      <Link to={`/app/${e.path}`} state={{ event: e }}>
                        <strong>{e.name}</strong>
                      </Link>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </>
    ) : null
  }
}
export default Events
